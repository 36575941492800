document.addEventListener("turbo:load", function() {
    $("#cpy-contacts").on("click", function() {
        $.ajax({
            url: "/contacts/outlook" + window.location.search,
            dataType: "text",
            async: false, // Very important, otherwise Clipboard access is denied
            type: "GET",
            success: function(data) {
                var copyFrom = document.createElement("textarea");
                document.body.appendChild(copyFrom);
                copyFrom.textContent = data;
                copyFrom.select();
                document.execCommand("copy");
                copyFrom.remove();
            }
        });
    });

    $('#search input[name*="search[contacts.conseiller]"]').on('change', function() {
      $('input[name*="search[contacts.main_contact]"]').removeProp('checked');
      $('input[name*="search[contacts.management]"]').removeProp('checked');
    });

    $('#search input[name*="search[contacts.main_contact]"]').on('change', function() {
      $('input[name*="search[contacts.conseiller]"]').removeProp('checked');
    });

    $('#search input[name*="search[contacts.management]"]').on('change', function() {
      $('input[name*="search[contacts.conseiller]"]').removeProp('checked');
    });
});