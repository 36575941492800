import "@hotwired/turbo-rails";

import $ from "jquery";
window.$ = $;

import Rails from "@rails/ujs";
Rails.start();

import * as bootstrap from "bootstrap";
window.Bootstrap = bootstrap; // Necessary for popper.js

import "popper.js";

import select2 from "select2";
select2();

import "../libs/js/eip"
import "../javascripts/clients"
import "../javascripts/contacts"



document.addEventListener("turbo:load", function () {
  $('.select2').each(function () {
    $(this).select2({placeholder: $(this).data('placeholder')});
  });
});

$.ajaxSetup({
  headers: {'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')}
});