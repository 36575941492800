document.addEventListener("turbo:load", function () {
  $('#client_suppliers_form').find(".editable").editable({
    onChange: function (event) {
      doAjaxUpdate($(event.parent).closest('tr').data('supplier'), event.editableElement.id, event.newValue);
    }
  });

  $('#client_suppliers_form').find(':checkbox').not('[name="link_supplier"]').change(function (event) {
    doAjaxUpdate($(this).closest('tr').data('supplier'), this.id, this.checked);
  });

  $('#client_suppliers_form').find('[name="link_supplier"]').change(function (event) {
    var clientId = $('#client_suppliers_form').data('client');
    var tr = $(this).parent().parent();
    var supplierId = tr.data('supplier');
    if (this.checked) {
      $.ajax({
        url: "/clients/" + clientId + "/client_suppliers.json",
        type: "POST",
        data: {
          client_supplier: {
            supplier_id: supplierId,
            client_id: clientId
          }
        },
        success: function (data) {
          tr.find("#direct_account").removeAttr("disabled");
          tr.find("#account_number").parent().removeAttr("disabled");
        }
      });
    } else {
      $.ajax({
        url: "/clients/" + clientId + "/unlink_supplier.json",
        type: "DELETE",
        data: {
          supplier_id: supplierId
        },
        success: function (data) {
          tr.find("#direct_account").removeProp("checked");
          tr.find("#direct_account").attr("disabled", "disabled");
          tr.find("#account_number").text("");
          tr.find("#account_number").parent().attr("disabled", "disabled");
        }
      });
    }
  });

  function doAjaxUpdate(supplierId, field, value) {
    var clientId = $('#client_suppliers_form').data('client');
    $.ajax({
      url: "/clients/" + clientId + "/client_suppliers/ajax_update.json",
      type: "PUT",
      data: {
        supplier_id: supplierId,
        client_supplier: {
          [field]: value
        }
      },
      success: function (data) {
        
      }
    });
  }
});